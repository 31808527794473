h1 {
    margin-bottom: 1rem;
}

p.pressButton {
    margin-top: 1rem;
}

.contentWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
}

.loading {
    display: inline-block;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;

    @keyframes l {
        to {
            clip-path: inset(0 -1ch, 0 0);
        }
    }
}