@import "../../../../../../../themes/breakpoints";

.dayIcon {
  @include less-1000 {
    max-width: 32px;
    height: 32px;
    font-size: 14px !important;
  }
  @include more-1000 {
    max-width: 40px;
    max-height: 40px;
    font-size: 16px !important;
  }
  @include for-small-phone {
    max-width: 30px;
    max-height: 30px;
    font-size: 14px !important;
  }
}