@import "../../../../../../themes/colors";

.dropdownDescription {
  margin: 0;
  font-weight: 400;
  color: var(--p-muted-color);
}

.monthWrapper {
  display: flex;
  justify-content: center;
  padding-top: 0.2rem;
  background: var(--card-background-color);
  border-radius: 0.25rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.yearSelector {
  margin-bottom: 0.4rem;
}

.selectionInfoWrapper {
  display: flex;
  justify-content: space-between;
  background: var(--card-background-color);
  border: none;
  border-radius: 8px;

  .first {
      display: flex;
      align-items: center;
      padding: 1.2rem;
  }

  .second {
    display: flex;
    align-items: center;
    padding: 1.2rem;
  }

  .actionIconWrapper {
      padding: 1.2rem;
  }
}

.methodIcon {
  width: 62px;
  height: 41px
}

.methodBody {
  text-align: center;
}

.actionButton {
  width: unset !important;
  align-self: center;
}

.addButton {
  fill: #565555
}

.chooseButton {
  width: 22px;
  height: 22px;
}

.deleteButton {
  margin-left: 0.1rem;
}