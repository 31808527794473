.actionIcon {
  border-radius: 20px;
  display: inline-block;
  &:hover {
    background: #282727;
  }
}

.actionMenu {
  background: #1F1F1F;
  border-radius: 8px;
  color: #565555;
}

.actionItem {
  text-transform: none;
  font-size: 0.85rem;

  &:hover {
    background: #181818 !important;
    color: #FFFFFF;
    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.iconText {
  margin-left: 0.5rem;
}