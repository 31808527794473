@import "../../../../themes/colors.scss";
@import "../../../../themes/breakpoints";

$bg-hover-menu: #1F1F1F;
$active-text-color: #FFFFFF;
$menu-text-color: #565555;

.menuItem {
  padding: 10px 0 10px 27px;
  width: 100%;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: $menu-text-color;
  margin: 0.4rem 0;

  &:hover {
    background-color: $bg-hover-menu;


    svg {
      color: var(--text-menu);
    }

    @include more-1000 {
      border-radius: 8px;
    }
    @include less-1000 {
      border-radius: 0;
    }
  }

  &>div {
    display: flex;
    align-items: flex-start;
  }
}

.svg {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 19px;
  width: 100%;
  min-width: 185px;
}

.active {
  background-color: $bg-hover-menu;
  cursor: default;
  color: $active-text-color;

  &>div {
    &:hover {
      background-color: $bg-hover-menu;
    }
  }
  &:hover {
    background-color: $bg-hover-menu;
  }

  @include more-1000 {
    border-radius: 8px;
  }
  @include less-1000 {
    border-radius: 0;
  }
}
