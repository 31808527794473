@import "../../themes/breakpoints.scss";

.mainContainer {
  width: 100%;
  display: flex;
  height: 100vh;

  @include less-1000 {
    flex-direction: column;
  }
}
