@import "../../../../themes/colors.scss";

.warningBanner {
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 8px;
    color: var(--primary);
    background: var(--primary-background);
}

.warningBannerIcon {
    display: flex;
    align-items: center;
}

p {
    margin: 0;
    color: inherit;
}