@import "../../../../../../../themes/breakpoints";

.dayInfo {
  font-weight: 400;
  @include more-1000 {
    font-size: 16px;
  }
  @include less-1000 {
    font-size: 14px;
  }
}

.dayIconWrapper {
  @include for-small-phone {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  @include less-1000 {
    max-width: 32px;
    height: 32px;
    font-size: 14px;
  }
  @include more-1000 {
    max-width: 40px;
    max-height: 40px;
    font-size: 16px;
  }
}

.dayIcons {
  @include more-1000 {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
  }
  @include less-1000 {
    display: grid;
    gap: 0.8rem;
  }
}