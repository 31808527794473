.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.loading {
  display: inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
  @keyframes l {
    to {
      clip-path: inset(0 -1ch, 0 0);
    }
  }
}
