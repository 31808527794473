@import "../../themes/colors.scss";
@import "../../themes/breakpoints.scss";

.banner {
    display: flex;
      border: none transparent;
      border-radius: 0.5rem;
      padding-block: 1em;
      padding-inline: 2em;
      margin-bottom: 1.5rem;
      background: var(--card-background-color);
      flex-direction: row;
      justify-content: space-between;
      &:first-child {
        @include less-1000 {
          flex-direction: column;
        }
      }
      h1 {
        font-size: 64px;
        margin: 0;
      }
  }