@import "../../../../../../themes/breakpoints";
@import "../../../../../../themes/colors";

.banner {
  display: flex;
    border: none transparent;
    border-radius: 0.5rem;
    padding-block: 1em;
    padding-inline: 1.5em;
    background: var(--card-background-color);
    flex-direction: row;
    justify-content: space-between;
    &:first-child {
      @include less-1000 {
        flex-direction: column;
      }
    }
}

.banner.active {
  @include less-1000 {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    border-left: 0.2rem solid transparent;
    border-left-color: var(--status-active);
  }
}

.banner.waiting {
  @include less-1000 {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    border-left: 0.2rem solid transparent;
    border-left-color: var(--status-waiting);
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include more-1000 {
    margin-bottom: 0;
  }
  @include less-1000 {
    margin-bottom: 1.2rem;
  }

  div {
    margin-bottom: 0.3rem;
    font-weight: 400;
    font-style: normal;
  }

  span {
    font-weight: 400;
    font-style: normal;
  }
}

.status {
  align-self: center;

  @include more-1000 {
    display: block;
  }

  @include less-1000 {
    display: none;
  }
}

.buttonWrapper {
  flex: stretch;
  @include more-1000 {
    align-self: center;
  }
  @include less-1000 {
    justify-content: stretch;
  }
}

.actionButton {
  width: unset;
  padding: 16px 24px;
  @include more-1000 {
    width: unset !important;
    align-self: center;
  }

  @include less-1000 {
    width: 100%;
    align-self: unset;
  }
}