.letterLimit {
  color: #565555;
  font-size: 0.75rem;
  margin-bottom: var(--spacing);
}

.error {
  font-size: 0.75rem;
  color: #F26D6B;
  margin-bottom: var(--spacing);
}

span.optional {
  color: var(--p-muted-color)
}

label.wrapper {
  margin-bottom: calc(var(--spacing) * 1.2);
}