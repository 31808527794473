@import "../../themes/colors";
@import "../../themes/breakpoints";

button.logout {
  width: unset;
  align-self: unset;
}

.profileName {

  h4 {
    color: var(--color-main);
    margin: 0 0 0.5rem 0;
  }
}

.profile {
  align-items: center;

  @include less-1000 {
    display: block;
  }

  @include more-1000 {
    display: flex;
  }
}

input.disabledInput[disabled] {
  --border-color: var(--form-element-border-color);
  opacity: 1;
  background: none;
  pointer-events: stroke;
}