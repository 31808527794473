.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.icon {
  width:6rem;
  height:6rem;
  background: #1F1F1F;
  border-radius: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg {
    width:2.2rem;
    height:2.2rem;
    path {
      fill: white;
    }
  }
}

.text {
  color: #FFFFFF;
  font-size: 1.2rem;
  text-align: center;
}

.subtext {
  color: #565555;
  text-align: center;
}
