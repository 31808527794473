@import "../../../../../../themes/breakpoints";
@import "../../../../../../themes/colors";

.monthCell {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
  @include less-1000 {
    width: 32px;
    height: 32px;
    font-size: 14px !important;
  }
  @include more-1000 {
    width: 40px;
    height: 40px;
    font-size: 16px !important;
  }
  @include for-small-phone {
    width: 30px;
    height: 30px;
    font-size: 14px !important;
  }
}

.monthCell.weekday {
  color: var(--p-muted-color);
}

.monthWeekdays {
  @include more-1000 {
    column-gap: 1.2rem;
  }
  @include less-1000 {
    column-gap: 0.9rem;
  }
}

.monthWeeknumbers {
  @include more-1000 {
    column-gap: 1.2rem;
  }
  @include less-1000 {
    column-gap: 0.9rem;
  }
}
