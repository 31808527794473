.statusWrapper {
  display: flex;
  align-items: center;
}

.statusIcon {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 4px;
  margin-top: -0.1rem;
}

.statusText {
  margin-left: 0.5rem;
}
