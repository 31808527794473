@import "../../../../themes/colors";

.dropdown {
  border-color: #2C2B2B;

  :required {
    border-color: #727171;
  }
  option {
    color: var(--color-main)
  }
}

span.optional {
  color: var(--p-muted-color)
}

div.wrapper {
  label {
    margin-bottom: calc(var(--spacing) * 1.2);
  }
}