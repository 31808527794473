@import "../../../../themes/colors";
@import "../../../../themes/breakpoints";

.banner {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background: var(--subscription-banner-background-color);
  font-family: "Roboto", sans-serif;

  h5 {
    color: var(--color-main);
    margin-bottom: 0.4rem;
    font-weight: inherit;
  }

  p {
    font-weight: inherit;
  }

  button {
    margin-top: 1rem;
  }

  @include more-1000 {
    font-weight: 500;
    border-radius: 0.4rem;

    h5 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
    }
  }

  @include less-1000 {
    margin: 0;
    border-radius: 0;
    font-weight: 400;

    h5 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}