@import "../../themes/breakpoints.scss";
.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 1000px;
    margin: 0 auto;

    div.logo {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 5rem;
    }

    @include less-1000 {
        flex-direction: column;
    }
}