.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
}

.body {
  display: flex;
  justify-content: center;
}
