@import "../../../../../../themes/breakpoints";
@import "../../../../../../themes/colors";

.banner {
  display: flex;
  border: none transparent;
  border-radius: 0.5rem;
  padding-block: 1.2em;
  padding-inline: 1.2em;
  background: var(--subscription-banner-background-color);
  row-gap: 1rem;
  @include more-1000 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .content {
      svg {
        width: auto;
        height: auto;
        margin-bottom: 0;
      }
    }
  }
  &:first-child {
    @include less-1000 {
      flex-direction: column;
      align-items: stretch;

      .content {
        svg {
          width: 40px;
          height: 40px;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .content {
    display: flex;
    column-gap: 1.5rem;
    flex-wrap: wrap;

    h4 {
      color: var(--color-main);
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      @include more-1000 {
        font-size: 18px;
      }
      @include less-1000 {
        font-size: 14px;
      }
    }
  }

  button {
    width: unset !important;
    padding-inline: 1.2rem;
    justify-self: flex-end;
  }

  .nextPayment {
    display: flex;
    flex-direction: column;
    font-weight: 400;

    span {
      @include more-1000 {
        font-size: 16px;
      }
      @include less-1000 {
        font-size: 14px;
      }
    }

    p {
      @include more-1000 {
        font-size: 18px;
      }
      @include less-1000 {
        font-size: 16px;
      }
    }
  }
}