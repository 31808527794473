@import "../../../themes/breakpoints";
@import "../../../themes/colors";

.table {
  background: var(--form-element-background-color);
  margin-bottom: 0;
}

.tableRow {
  border: 5px solid var(--card-background-color);
  border-radius: 8px;

  td:last-child {
    background: var(--card-background-color);
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.tableCellActions {
  width: 150px;
  white-space: normal;
}


.tableCell {
  background: var(--card-background-color);
  border: none;
  width: 150px;
  white-space: normal;
  &:first-child {
    width: auto;
    white-space: initial;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    @include less-1000 {
      border: 3px none $red-spark;
      border-left-style: solid;
    }
    @include more-1000 {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}

