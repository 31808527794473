@import "../../../../../../../themes/colors.scss";

.monthSelector {
  background: var(--card-background-color);
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-bottom: 0;
  appearance: textfield !important;
  padding: 0;
  height: 40px;
  box-shadow: none;

  :focus {
    box-shadow: none;
  }
}