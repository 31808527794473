@import "../../themes/colors.scss";
@import "../../themes/breakpoints.scss";

$bg-menu: #181818;

.desktopMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position:sticky;
  top: 0;
  min-height: 100vh;
  height:100%;
  background-color: $bg-menu;
  overflow: hidden;
  width:300px;
  overflow-y: auto;
  padding: 0 24px 24px;

  @include less-1000 {
    display: none;
  }
}

.mobileMenu {
  position:sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  min-height: 64px;
  background: $bg-menu;

  @include more-1000 {
    display: none;
  }
}

.opened {
  width: 100%;
  height: 100%;
  background: $bg-menu;
  position: fixed;
  top: 64px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .openedMenuWrapper {
    width: inherit;
    overflow-y: auto;
  }

  .openedSubscriptionWrapper {
    margin-bottom: 4rem;

  }
}

.menuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo {
  cursor: pointer;
  align-self: flex-start;
  margin: 20px 0 20px 16px;
}

.subscriptionBanner {
  width: 100%;
}
