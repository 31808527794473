.no-shadow:focus {
  box-shadow: none;
}
.root{
  position:relative;
  margin-bottom: calc(var(--spacing) * 1.2)
}
.tag{
  background: #1F1F1F;
  border-radius: 8px;
  color: #FFFFFF !important;
  svg { fill: #565555; }
  margin:16px 16px 0px 0px;
  display: flex;
  align-items: center;
  height: 24px;

  line-height: 22px;
  border: 1px solid #727171;
  box-sizing: content-box;
  padding: 4px 2px 4px 12px;
  outline: 0;
  overflow: hidden;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
  }
}
.listBox{
  width:100%;
  background: #121212;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;

  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #1F1F1F;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }
}
.wrapperFocus{
  --border-color: var(--form-element-active-border-color)!important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color)!important;
}

.inputWrapper{
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --color: var(--form-element-color);
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  outline: none;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  font-style: normal;
  font-size: 15.75px;
  line-height: 150%;

  &:hover {
  }

  &.focused {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    &:focus { box-shadow:none;};
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
}
.tagsWrapper{
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--form-element-spacing-horizontal)
}

